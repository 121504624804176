<template>
    <div class="page-group-invite-202412">
        <headeBar left-arrow :color="header_color" :title="header_title" :background="header_background"
            :opacity="header_opacity" :placeholder="false" @click-left="newAppBack" />
        <div class="headbg">
            <!-- <div class="yueka" @click="goBuy"></div> -->
        </div>
        <div class="djs">
            <div class="time">本月活动倒计时：{{ day }}天</div>
        </div>

        <div class="invite-box">
            <div class="subline">
                <div class="sub1" @click="openShare(1)">立即邀请</div>
                <div class="sub2" @click="openShare(2)"></div>
            </div>
        </div>
        <div class="gift-head">
            <div class="to-reward-list" @click="torewardlist">查看邀请明细
                <div class="arrow-icon" />
            </div>
        </div>
        <div class="gift-list">
            <div class="pdbox">
                <div class="invite-info">您已成功邀请<span class="puhui val">{{ inviteNum }}</span>人，累计获得<span
                        class="puhui val">{{ total }}</span>元消费金</div>
                <div class="tp">*奖励发放方式：每周将进行一次消费金发放，每周五发放上周所获得的消费金至会员超鹿商城账号。</div>
                <div class="banner1"
                    @click="goApplet('wx6756ac08908c3730', 'pages/home/dashboard/index', 'gh_fa9c9ac54dba')">
                    <img alt src="https://img.chaolu.com.cn/ACT/group-invite-202412/banner1.png" />
                </div>
                <div class="banner2" @click="toPath">
                    <img alt src="https://img.chaolu.com.cn/ACT/group-invite-202412/banner2.png" />
                </div>
            </div>
            <div class="level-model" v-for="(item, key) in rewardMap">
                <div class="level-head">
                    <div class="level-num">
                        <template v-if="key == 1">每邀请1人</template>
                        <template v-else>邀请第{{ key }}人</template>
                    </div>
                    <div class="finished" v-if="inviteNum >= key">
                        {{ key == 1 ? '已邀请' + inviteNum + '人' : '已达成' }}
                    </div>
                    <div class="nofinished" v-else>未达成</div>
                </div>

                <div class="reward-model">
                    <div class="level-img">
                        <img alt="" :src="item.pic" />
                    </div>
                    <div class="level-name-box">
                        <div class="level-name ellipsis-2">{{ item.name }}</div>
                        <div class="sub" v-if="inviteNum >= key">已获得
                            <span class="puhui ml" v-if="key == 1">
                                x<span class="val">{{ inviteNum }}</span>
                            </span>
                        </div>
                        <div class="sub op" v-else>未获得</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="rule-box">
            <img src="https://img.chaolu.com.cn/ACT/group-invite-202412/rule.png" alt />
        </div>
        <van-popup v-model="showCollectionRecords" position="bottom" safe-area-inset-bottom class="pop">
            <div class="pop-title">
                领取记录
                <van-icon name="cross" class="pop-close" color="#fff" @click="showCollectionRecords = false" />
            </div>
            <div class="collection-records">
                <div class="mode2" v-if="getObj.needAddress">
                    <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/map.png" alt=""
                        class="maplogo" />
                    <div class="pub_onefull" v-if="getObj.addressVO">
                        <div class="line1">{{ getObj.addressVO.province + getObj.addressVO.city +
                            getObj.addressVO.district
                            }}
                        </div>
                        <div class="line2">{{ getObj.addressVO.addressDetail }}</div>
                        <div class="line3">{{ getObj.addressVO.name }} <span class="phone">{{ getObj.addressVO.phone
                                }}</span> </div>
                    </div>
                </div>
                <div class="mode1" v-else>已发放至您的优惠券账户，<br />可至我的-优惠券内查看</div>
                <div class="jg"></div>
                <div class="records-box">
                    <div class="records-model">
                        <div class="records-img">
                            <img :src="getObj.rewardImg" alt="" />
                        </div>
                        <div class="records-info">
                            <div class="records-name">{{ getObj.rewardName }}</div>
                            <div class="records-tips" v-if="getObj.needAddress">确认发货后将进行短信告知</div>
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>

        <van-overlay :show="isShareWx" :z-index="1500" @click="isShareWx = false">
            <div class="tip-box" @click.stop>
                <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/anniversary2021/share-mini.png"
                    class="tip-img" />
            </div>
        </van-overlay>
        <ChooseTeacher @setShareTeacher="setShareTeacher" ref="refChooseTeacher" />
        <InviteList ref="refInviteList" />
        <common-share :value="showShare" :shareParams="shareParams" @close="showShare = false"
            @share-success="showShare = false" :shareItem="['posterfriend', 'postermessage', 'save']"
            :miniSharePoster="appTypeStr === 'mini'">
            <template slot="createDom">
                <div class="canvascss" v-if="shareTeacherObj">
                    <img src="https://img.chaolu.com.cn/ACT/group-invite-202412/sharebg-teacher.png"
                        class="canvascss_bg" />
                    <div class="tl">
                        <img :src="shareTeacherObj.teacherImg" class="uhead" />
                        <div class="pub_onefull">
                            <div class="uname">{{ shareTeacherObj.teacherName }}</div>
                        </div>
                    </div>
                    <div class="ul">
                        <img class="ucode" :src="userHeadImg" />
                        <div class="uinfo ellipsis">
                            <div class="u1 ellipsis">{{ nickName }}</div>
                            <div class="u2">给你推荐一个超棒的教练！</div>
                        </div>
                        <div class="wai">
                            <img class="nei" :src="userPoster" />
                        </div>
                    </div>
                </div>
                <div class="canvascss2" v-else>
                    <img src="https://img.chaolu.com.cn/ACT/group-invite-202412/sharebg.png" class="canvascss_bg" />
                    <div class="ul">
                        <img class="ucode" :src="userHeadImg" />
                        <div class="uinfo ellipsis">
                            <div class="u1 ellipsis">{{ nickName }}</div>
                            <div class="u2">推荐你来超鹿上团课开启燃<br />脂、飙汗的团课之旅~</div>
                        </div>
                        <div class="wai">
                            <img class="nei" :src="userPoster" />
                        </div>
                    </div>
                </div>
            </template>
        </common-share>
    </div>
</template>
<script>
import { newAppBack, initBack, saveCreateImg, webAppShare, goApplet } from '@/lib/appMethod';
import headeBar from '@/components/app/headBar';
import commonShare from '@/components/commonShare/newShare';
import userMixin from '@/mixin/userMixin';
import wx from 'weixin-js-sdk';
import headerMixin from '@/mixin/headerMixin';
import ChooseTeacher from './components/chooseTeacher.vue';
import InviteList from './components/inviteList.vue';

const webPath = `${window.location.origin}/#/superdeer-activity/group-invite-202412/accept`;
const webUrl = `pages/webView/index?webUrl=${encodeURIComponent(webPath)}`;
const activeId = 153;
export default {
    components: {
        headeBar,
        commonShare,
        ChooseTeacher,
        InviteList
    },
    mixins: [userMixin, headerMixin],
    data() {
        return {
            showCollectionRecords: false,//显示领取记录弹窗
            userHeadImg: '',
            nickName: '',
            userPoster: '',
            isShareWx: false,
            rewardMap: {
                1: {
                    pic: 'https://img.chaolu.com.cn/ACT/group-invite-202412/30.png',
                    name: '每邀请1人可得30元消费金'
                },
                3: {
                    pic: 'https://img.chaolu.com.cn/ACT/group-invite-202412/10.png',
                    name: '额外获得10元消费金'
                },
                5: {
                    pic: 'https://img.chaolu.com.cn/ACT/group-invite-202412/50.png',
                    name: '额外获得50元消费金'
                },
                7: {
                    pic: 'https://img.chaolu.com.cn/ACT/group-invite-202412/100.png',
                    name: '额外获得100元消费金'
                },
            },//奖品等级
            finished: false,
            pageNum: 1,
            pageSize: 10,
            popularityList: [],//排行榜
            showShare: false, // 是否显示分享弹窗
            showGet: false,//领取奖品弹窗
            shareParams: {
                title: '邀你0.01元体验莱美新套路～',
                content: '邀你0.01元体验莱美新套路～',
                multiple: '1',
                userName: 'gh_7f5b57b6a76e',
                miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2',
                url: 'url',
                miniImage: 'https://img.chaolu.com.cn/ACT/group-invite-202412/mini.png',
                path: webUrl,
            },
            voteNum: 0,
            inviteNum: 0,
            total: 0,
            day: 0,
            getObj: {},//确认奖品展示
            shareTeacherObj: null,
            rankNum: 3
        }
    },
    methods: {
        goApplet,
        saveCreateImg,
        newAppBack,
        toPath() {
            const path = '/superdeer-activity/marketing/leaflet';
            const id =332;
            if (this.appTypeStr === 'mini') {
                wx.miniProgram.navigateTo({
                    url: `/pages/webView/index?webUrl=${window.location.origin}/#${path}&userId=1&token=1&location=1&id=${id}`,
                })
            } else {
                this.$router.push(path+'?id='+id)
            }
        },
        onlyItem(item) {
            return item.some(d => {
                return d.status == 'CAN_RECEIVE'
            })
        },
        getName(v) {
            const json = {
                'RECEIVED': '已领取',
                'RECEIVED_OTHER': '已领取其他奖品',
                'CAN_RECEIVE': '领取',
                'TASK_FULFILLED': '任务未达成'
            }
            return json[v];
        },
        torewardlist() {
            this.$refs.refInviteList.open();
        },

        setShareTeacher(tObj) {
            if (tObj) {
                this.shareParams.path = `${webUrl}&inviteUid=${this.userId}&location=1&cityId=1&teacherId=${tObj.teacherId}`;
                this.shareTeacherObj = tObj;
            }
            else {
                this.shareParams.path = `${webUrl}&inviteUid=${this.userId}&location=1&cityId=1`;
                this.shareTeacherObj = null;
            }
            this.toShare();
        },
        async toShare() {
            if (this.shareType == 1) {
                this.wxInvite();
            } else if (this.shareType == 2) {
                await this.sharePoster();
                this.showShare = true;
            }
        },
        sharePoster() {
            const ps = {
                source: 'group-invite-202412',
                webUrl: webPath,
                cityId: 1,
                userId: 1,
                inviteUid: this.userId,
            }
            if (this.shareTeacherObj) {
                ps.teacherId = this.shareTeacherObj?.teacherId
            }
            console.log(ps);
            return this.$axios.post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
                hasWx: true,
                userId: this.userId,
                token: this.token,
                width: 240,
                height: 240,
                webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
                scene: JSON.stringify(ps)
            }).then((res) => {
                this.userPoster = 'data:image/png;base64,' + res.data.qrCode
                this.userHeadImg = res.data.headImg
                this.nickName = res.data.nickName
            })
        },
        wxInvite() {
            if (!this.userId) {
                this.$toast('请稍后再试');
                return;
            }

            if (this.appTypeStr === 'mini') {
                wx.miniProgram.postMessage({
                    data: {
                        type: 'share',
                        shareImage: this.shareParams.miniImage,
                        shareTitle: this.shareParams.title,
                        shareUrl: this.shareParams.path,
                    },
                })
                this.isShareWx = true
            } else {
                let params = {
                    scene: 0,
                    ...this.shareParams
                }
                let miniParams = JSON.parse(JSON.stringify(params))
                miniParams.image = miniParams.miniImage
                webAppShare('3', miniParams)
            }
        },
        openShare(type) {
            this.shareType = type;//1微信分享，2海报分享
            this.$refs.refChooseTeacher.show = true;
        },

        getRewardList() {
            this.$axios
                .post(this.baseURLApp + "/schedule/invitation/manualReceiveDetail", {
                    activeId,
                    userId: this.userId,
                    token: this.token
                })
                .then((res) => {
                    this.inviteNum = res.data.inviteNum;
                    this.day = res.data.activityDay;
                    let total = parseInt(this.inviteNum) * 30;
                    if (this.inviteNum >= 3) {
                        total += 10;
                    }
                    if (this.inviteNum >= 5) {
                        total += 50;
                    }
                    if (this.inviteNum >= 7) {
                        total += 100;
                    }
                    this.total = total;
                })
        },
        goBuy() {
            if (this.appTypeStr === 'mini') {
                wx.miniProgram.navigateTo({
                    url: `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/sell-membership/schedule&cityId=3`,
                })
            } else {
                this.$router.push('/superdeer-activity/sell-membership/schedule?cityId=3')
            }
        },
    },
    mounted() {
        this.bindHeaderScroll(this.$el, 200, (scrollTop, morehalf) => {

        });
    },
    async created() {
        console.log(this.$route.query);
        initBack();
        await this.$getAllInfo(['userId', 'cityId']); console.log(this.cityId);
        // this.getRanking();
        this.getRewardList();
        // this.getMyvote();
    }
}
</script>
<style lang="less" scoped>
.page-group-invite-202412 {
    background: #FEFAF1;
    position: absolute;
    height: 100vh;
    top: 0;
    // bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;

    div {
        box-sizing: border-box;
    }

    img {
        vertical-align: top;
        width: 100%;
    }

    .gray {
        opacity: 0.5;
        pointer-events: none;
    }

    .headbg {
        height: 638px;
        background: url(https://img.chaolu.com.cn/ACT/group-invite-202412/headbg.png);
        background-size: cover;

        .yueka {
            position: fixed;
            right: 4px;
            top: 410px;
            width: 152px;
            height: 152px;
            background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/yueka.png);
            background-size: cover;
            z-index: 99;
        }
    }

    .djs {
        display: flex;
        margin-left: 24px;
        margin-top: -125px;
        margin-bottom: 28px;

        .time {
            height: 48px;
            line-height: 1;
            background: linear-gradient(360deg, #EA6133 0%, #F1A052 100%);
            border-radius: 8px;
            padding: 0 20px;
            font-size: 24px;
            color: #fff;
            font-weight: bold;
            display: flex;
            align-items: center;
            border: 1px solid #FFDAB9;
        }
    }

    .pop-get {
        border-radius: 16px;
        width: 560px;
        height: 790px;
        background: linear-gradient(180deg, #E6FAFF 0%, #FBE6CA 100%);
        text-align: center;

        .pop-get-title {
            color: #822D13;
            font-weight: bold;
            font-size: 44px;
            margin-top: 64px;
            margin-bottom: 24px;
        }

        .pop-get-tips {
            color: #822D13;
            font-size: 24px;
            line-height: 36px;
        }

        .jp {
            width: 240px;
            margin: 70px auto;
        }

        .pop-get-sub {
            width: 360px;
            line-height: 96px;
            color: #fff;
            background: linear-gradient(to right, #FF7F37 0%, #FF3672 100%);
            margin: 0 auto 30px;
            border-radius: 10px;
            font-weight: bold;
        }

        .noget {
            font-size: 28px;
            color: #822D13;
        }
    }

    .popularity-lists {
        margin: 20px 24px 24px;

        .popularity-head {
            height: 102px;
            background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/phb.png);
            background-size: cover;
            position: relative;

            .toranking {
                display: flex;
                position: absolute;
                right: 20px;
                top: 18px;
                color: #fff;
                font-size: 28px;
                font-weight: bold;
                align-items: center;

                .arrow-icon {
                    width: 32px;
                    height: 32px;
                    background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/arrow.png);
                    background-size: cover;
                    margin-left: 16px;
                }
            }
        }

        .popularity-body {
            background: linear-gradient(130deg, #FFF2D9 0%, #FFE4D6 100%);
            padding: 28px;
            border-bottom-right-radius: 16px;
            border-bottom-left-radius: 16px;

            .b-list {
                .b-model {
                    border-radius: 8px;
                    background: #FEFAF1;
                    height: 108px;
                    margin-bottom: 16px;
                    display: flex;
                    align-items: center;
                    padding: 0 28px 0 22px;

                    .l1 {
                        width: 48px;
                        height: 48px;
                        background-size: cover;

                    }

                    &.rank1 {
                        .pimg {
                            background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/p1.png);
                        }

                        .l2 {
                            border: 4px solid #FFA902;
                        }
                    }


                    &.rank2 {
                        .pimg {
                            background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/p2.png);
                        }

                        .l2 {
                            border: 4px solid #789EFF;
                        }
                    }

                    &.rank3 {
                        .pimg {
                            background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/p3.png);
                        }

                        .l2 {
                            border: 4px solid #DD6C2C;
                        }
                    }

                    .l2 {
                        margin: 0 24px;
                        width: 80px;
                        height: 80px;
                        border-radius: 50%;
                        position: relative;
                        background: #D0D0D0;
                        overflow: hidden;

                        &::after {
                            position: absolute;
                            content: '空缺中';
                            font-size: 24px;
                            white-space: nowrap;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%) scale(0.8);
                            color: #fff;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            position: relative;
                            z-index: 2;
                        }
                    }

                    .l3 {
                        font-weight: bold;
                        font-size: 28px;
                        color: #242831;
                        flex: 1;
                    }

                    .l4 {
                        font-size: 24px;
                        text-align: right;
                        color: #7B5340;
                        font-weight: bold;

                        .number {
                            color: #FF5C25;
                        }
                    }
                }

                .more {
                    height: 68px;
                    border-radius: 8px;
                    background: #FEFAF1;
                    color: #242831;
                    font-size: 24px;
                    text-align: center;
                    line-height: 68px;

                    .arrow {
                        margin-left: 8px;
                    }
                }

                .suctips {
                    margin-top: 24px;
                    font-size: 24px;
                    color: #734631;
                    line-height: 38px;
                }
            }
        }
    }

    .invite-box {
        margin: 0 24px;
        height: 324px;
        background: url(https://img.chaolu.com.cn/ACT/group-invite-202304/invitebg.png);
        background-size: cover;
        position: relative;

        .subline {
            position: absolute;
            left: 28px;
            right: 28px;
            bottom: 28px;
            display: flex;
            align-items: center;
        }

        .sub1 {
            width: 248px;
            border: 1px solid #fff;
            font-size: 36px;
            margin-right: 28px;
            height: 88px;
            border-radius: 92px;
            text-align: center;
            font-weight: bold;
            line-height: 88px;
            color: #fff;
        }

        .sub2 {
            flex: 1;
            height: 96px;
            background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/sub.png);
            background-size: 100% 100%;
        }
    }

    .gift-head {
        height: 95px;
        background: url(https://img.chaolu.com.cn/ACT/group-invite-202412/suchead.png);
        background-size: cover;
        position: relative;
        margin: 24px 24px 0;

        .to-reward-list {
            display: flex;
            position: absolute;
            right: 30px;
            top: 22px;
            color: #fff;
            font-size: 24px;
            font-weight: bold;
            align-items: center;

            .arrow-icon {
                width: 32px;
                height: 32px;
                background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/arrow.png);
                background-size: cover;
                margin-left: 16px;
            }
        }
    }

    .gift-list {
        background: #FFEED3;
        margin: 0 24px;
        padding-bottom: 40px;
        position: relative;
        border-radius: 0px 0px 16px 16px;
        .pdbox {
            padding: 0 28px;
        }

        .tp {
            margin-top: 20px;
            font-weight: 500;
            line-height: 32px;
            font-size: 22px;
            color: #734631;
            text-align: left;
        }

        .invite-info {
            font-size: 24px;
            color: #000000;

            .val {
                font-size: 48px;
                margin: 0 10px;
            }
        }

        .banner1 {
            margin: 38px 0 24px;
        }

        .banner2 {
            margin-bottom: 48px;
        }

        .level-model {
            padding-bottom: 48px;
            &:last-of-type{
                padding-bottom: 0;
            }
            .level-head {
                display: flex;
                align-items: center;

                .level-num {
                    background: url(https://img.chaolu.com.cn/ACT/group-invite-202412/coner.png);
                    background-size: 100% 100%;
                    // width: 140px;
                    padding: 0 20px;
                    height: 64px;
                    line-height: 72px;
                    text-align: center;
                    color: #fff;
                    font-size: 28px;
                    position: relative;
                    top: -4px;
                    left: -6px;
                    margin-right: 14px;
                    font-weight: bold;
                }

                .finished {
                    font-size: 26px;
                    color: #FD3665;
                    font-weight: bold;
                    position: relative;
                    padding-left: 40px;
                    line-height: 1;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        margin-top: -15px;
                        width: 30px;
                        height: 30px;
                        background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/finished.png);
                        background-size: cover;
                    }
                }

                .nofinished {
                    font-weight: bold;
                    font-size: 26px;
                    color: #242831;
                }
            }

            .reward-model {
                height: 196px;
                border-radius: 10px;
                background: #fff;
                // border: 1px solid #F9DDC3;
                margin: 28px;
                display: flex;
                align-items: center;
                padding: 28px 32px;

                .level-img {
                    width: 140px;
                    height: 140px;

                    img {
                        height: 100%;
                        width: 100%;
                    }
                }

                .level-name-box {
                    flex: 1;
                    margin-left: 50px;

                    .level-name {
                        font-size: 28px;
                        color: #000;
                        font-weight: bold;
                    }

                    .sub {
                        border-radius: 8px;
                        background: #FD3665;
                        text-align: center;
                        height: 52px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 172px;
                        margin: 25px 0 0;
                        font-size: 24px;
                        color: #fff;
                        font-weight: bold;

                        .ml {
                            margin-left: 13px;
                            font-weight: normal
                        }

                        .val {
                            font-size: 30px;
                        }
                    }
                }



                .op {
                    opacity: 0.5;
                }

                .hasget {
                    font-size: 22px;
                }
            }
        }



    }

    .rule-box {
        margin: 32px 24px;
        padding-bottom: 100px;
    }

    .tip-box {
        position: relative;

        .tip-img {
            position: absolute;
            top: 7px;
            right: 130px;
            width: 300px;
        }
    }

    .pop {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        .pop-title {
            color: #432F17;
            font-weight: bold;
            background: linear-gradient(180deg, #FCF2DA 0%, #FFFFFF 100%);
            font-size: 40px;
            text-align: center;
            line-height: 120px;
            position: relative;

            .pop-close {
                position: absolute;
                right: 32px;
                top: 38px;
                width: 44px;
                height: 44px;
                line-height: 44px;
                border-radius: 50%;
                background: rgba(39, 40, 49, 0.2);
                font-size: 28px;
            }
        }

    }

    .collection-records {
        .mode1 {
            font-size: 26px;
            color: #5A0A00;
            text-align: center;
            padding: 32px 0;
        }

        .mode2 {
            display: flex;
            align-items: center;
            padding: 40px 32px 56px;
            position: relative;

            &::after {
                content: '';
                height: 4px;
                background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/border.png);
                background-size: cover;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
            }

            .maplogo {
                width: 40px;
                height: 40px;
                margin-right: 20px;
            }

            .line1 {
                font-size: 24px;
                color: #666;
            }

            .line2 {
                color: #242831;
                font-size: 30px;
                font-weight: bold;
                margin: 20px 0;
            }

            .line3 {
                color: #242831;
                font-size: 26px;

                .phone {
                    color: #666;
                }
            }
        }

        .jg {
            height: 16px;
            background: #F5F5F5;
        }

        .records-box {
            min-height: 520px;
        }

        .records-model {
            display: flex;
            padding: 48px 32px 0;

            .records-img {
                width: 160px;
                height: 160px;
                margin-right: 40px;
            }

            .records-name {
                font-size: 28px;
                font-weight: bold;
                color: #242831;
                margin-bottom: 20px;
            }

            .records-tips {
                font-size: 22px;
                color: #666;
                line-height: 34px;
            }
        }
    }

    @bei: 1px;

    .canvascss {
        position: fixed;
        bottom: -200%;
        // top: 0;
        width: @bei*654;
        height: @bei*1204;
        margin: 0 auto;
        box-sizing: border-box;

        .canvascss_bg {
            width: 100%;
            height: 100%;
        }

        .tl {
            position: absolute;
            left: @bei*36px;
            top: @bei*36px;
            display: flex;
            align-items: center;
            color: #fff;
            font-size: @bei*32px;

            .uhead {
                width: @bei*88;
                height: @bei*88;
                border-radius: 50%;
                margin-right: @bei*24;
            }

            .uname {
                font-weight: bold;
            }
        }

        .ul {
            position: absolute;
            left: @bei*40px;
            bottom: @bei*40px;
            right: @bei*40;
            display: flex;
            align-items: flex-end;
            color: #fff;

            .ucode {
                z-index: 1;
                width: @bei*104;
                height: @bei*104;
                border-radius: 50%;
                overflow: hidden;
                margin-right: @bei*20px;
            }

            .uinfo {
                flex: 1;
                margin-bottom: @bei*16;
                margin-right: @bei*20;

                .u1 {
                    font-size: @bei*28px;
                    font-weight: bold;
                    color: #483022;
                    margin-bottom: @bei*10px;
                }

                .u2 {
                    color: #242831;
                    font-size: @bei*20px;
                }
            }

            .wai {
                border: @bei*3 solid #FFAD7A;
                border-radius: @bei*16px;
                width: @bei*134;
                height: @bei*134;
                padding: @bei*6px;

                .nei {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .canvascss2 {
        position: fixed;
        bottom: -200%;
        // top: 0;
        width: @bei*654;
        height: @bei*1102;
        margin: 0 auto;
        box-sizing: border-box;

        .canvascss_bg {
            width: 100%;
            height: 100%;
        }

        .ul {
            position: absolute;
            left: @bei*40px;
            bottom: @bei*40px;
            right: @bei*40;
            display: flex;
            align-items: flex-end;
            color: #fff;

            .ucode {
                z-index: 1;
                width: @bei*104;
                height: @bei*104;
                border-radius: 50%;
                overflow: hidden;
                margin-right: @bei*20px;
            }

            .uinfo {
                flex: 1;
                margin-right: @bei*20;

                .u1 {
                    font-size: @bei*28px;
                    font-weight: bold;
                    color: #483022;
                    margin-bottom: @bei*10px;
                }

                .u2 {
                    color: #242831;
                    font-size: @bei*20px;
                }
            }

            .wai {
                border: @bei*3 solid #FFAD7A;
                border-radius: @bei*16px;
                width: @bei*134;
                height: @bei*134;
                padding: @bei*6px;

                .nei {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    /deep/.canvas-box {
        padding-left: 40px !important;
        padding-right: 40px !important;
        box-sizing: border-box;

        .create-img {
            width: 100% !important;
        }
    }
}
</style>
