import { render, staticRenderFns } from "./chooseTeacher.vue?vue&type=template&id=3637653a&scoped=true&"
import script from "./chooseTeacher.vue?vue&type=script&lang=js&"
export * from "./chooseTeacher.vue?vue&type=script&lang=js&"
import style0 from "./chooseTeacher.vue?vue&type=style&index=0&id=3637653a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3637653a",
  null
  
)

export default component.exports